import FallbackLoader from '../Components/FallbackLoader';
import Loader from '../Components/Loader';
import React, { lazy, Suspense } from 'react';

// Component for lazy loading with suspense
const LazyRouteElement = ({ component }) => {
  const Component = lazy(() => import(`../Pages/${component}`));

  return (
    <Suspense fallback={<FallbackLoader />}>
      <Component />
    </Suspense>
  );
};

export default LazyRouteElement;
