import { useAlert } from 'react-alert';
import MiddleService from '../API/MiddleService';
import React, { useState, useEffect } from "react";
import { fetchUserName, fetchUserType, getUserId } from '../Service/service';

const Dashboard = () => {

    const alert = useAlert();
    const userType = fetchUserType();
    const [dashboardData, setDashboardData] = useState();

    useEffect(() => {
        fetchDashboardData();
    }, [])

    const fetchDashboardData = async () => {
        try {
            const payload = {
                "user_type": userType === 'admin' ? 1 : 2,
                "user_id": await getUserId(),
            };
            const response = await MiddleService.postData('dashboard', payload);
            if (response.status_code === 200) {
                const { data } = response;
                setDashboardData(data);
            } else {
                alert.error('Something Went Wrong !!');
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
    };


    return (
        <>
            <div class="content-wrapper">
                <div class="row">
                    <div class="col-md-12 grid-margin">
                        <div class="row">
                            <div class="col-12 col-xl-8 mb-4 mb-xl-0">
                                <h3 class="font-weight-bold">Welcome {fetchUserName()}</h3>
                                {/* <h6 class="font-weight-normal mb-0">All systems are running smoothly! You have <span class="text-primary">3 unread alerts!</span></h6> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">

                    {
                        userType === 'admin' ?
                            <>
                                {/* <div class="col-md-3 mb-4 stretch-card transparent">
                                    <div class="card card-tale">
                                        <div class="card-body">
                                            <p class="mb-4">Total reseller</p>
                                            <p class="fs-30 mb-2">{dashboardData?.total_reseller || 0}</p>
                                        </div>
                                    </div>
                                </div> */}
                                <div class="col-md-3 mb-4 stretch-card transparent">
                                    <div class="card card-dark-blue">
                                        <div class="card-body">
                                            <p class="mb-4">Total user</p>
                                            <p class="fs-30 mb-2">{dashboardData?.total_user || 0}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="col-md-3 mb-4 stretch-card transparent">
                                    <div class="card card-light-blue">
                                        <div class="card-body">
                                            <p class="mb-4">Validity to reseller</p>
                                            <p class="fs-30 mb-2">34040</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-4 stretch-card transparent">
                                    <div class="card card-light-danger">
                                        <div class="card-body">
                                            <p class="mb-4">Validity User</p>
                                            <p class="fs-30 mb-2">47033</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-4 stretch-card transparent">
                                    <div class="card card-tale">
                                        <div class="card-body">
                                            <p class="mb-4">SMS count User </p>
                                            <p class="fs-30 mb-2">4006</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-4 stretch-card transparent">
                                    <div class="card card-dark-blue">
                                        <div class="card-body">
                                            <p class="mb-4">SMS count to reseller </p>
                                            <p class="fs-30 mb-2">61344</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-4 stretch-card transparent">
                                    <div class="card card-light-blue">
                                        <div class="card-body">
                                            <p class="mb-4">Used count by user</p>
                                            <p class="fs-30 mb-2">34040</p>
                                        </div>
                                    </div>
                                </div> */}
                            </>
                            :
                            <>
                                <div class="col-md-3 mb-4 stretch-card transparent">
                                    <div class="card card-tale">
                                        <div class="card-body">
                                            <p class="mb-4">Total Remaning Credits</p>
                                            <p class="fs-30 mb-2">{dashboardData?.remain_credit || 0}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-4 stretch-card transparent">
                                    <div class="card card-dark-blue">
                                        <div class="card-body">
                                            <p class="mb-4">Total Remaning Months</p>
                                            <p class="fs-30 mb-2">{dashboardData?.remain_month || 0}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-4 stretch-card transparent">
                                    <div class="card card-light-danger">
                                        <div class="card-body">
                                            <p class="mb-4">Total User</p>
                                            <p class="fs-30 mb-2">{dashboardData?.total_user || 0}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="col-md-3 mb-4 stretch-card transparent">
                                    <div class="card card-light-blue">
                                        <div class="card-body">
                                            <p class="mb-4">Number of Meetings</p>
                                            <p class="fs-30 mb-2">34040</p>
                                        </div>
                                    </div>
                                </div>
                                */}
                            </>

                    }



                </div>
            </div>
        </>
    )
}

export default Dashboard;