import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import MiddleService from '../API/MiddleService';
import socket from '../Socket/index';
import { fetchAuthToken } from '../Service/service';
import Loader from '../Components/Loader';

const QRCodePage = () => {

    const [qrCode, setQRCode] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        socket.emit('whatsapp_code', fetchAuthToken());
        socket.on('whatsapp_code', (data) => {
            callAPI(data);
        });

        socket.on('whatsapp_ready', (data) => {
            const reponse = data ? JSON.parse(data) : '';
            navigate('/app/whatsappMessage', { state: { secreatKey: reponse?.api_key } });
        });
    }, []);

    const callAPI = (response) => {
        if (response) {
            setQRCode(response);
        }
    }

    return (
        <>
            <div class="content-wrapper">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h4 class="whatsapp-title">To use WhatsApp on your computer.</h4>
                                        <ol className="mt-5 whatsapp-QR">
                                            <li>Open <b>Whastapp</b> on your phone</li>
                                            <li>Tap <b>Menu</b> <span className="backgroud-gray"><i className="fa fa-ellipsis-v"></i></span> or <b>Setting</b> <span className="backgroud-gray"><i className="fa fa-cog"></i></span> and select WhatsApp Web</li>
                                            <li>Point your phone to this screen to captue the code</li>
                                        </ol>
                                        <Link class="nav-link" state={{ secreatKey: 'ABCD' }} to="/app/whatsappMessage">
                                            <div className="pt-2 ">
                                                <button type="button" class="btn btn-primary btn-rounded btn-fw">Connect Whatsapp</button>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4 centerdiv">
                                        {
                                            qrCode ? <QRCode className="img_qr_reffrela p-3" value={qrCode} />
                                                :
                                                <Loader message="QR Code is Loading..." />
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QRCodePage;