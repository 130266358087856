import io from 'socket.io-client';
import { socket_url } from '../API/CommonAPI';
import { generateRandomId } from '../Service/service';

const getLocalStorage = localStorage.getItem('randomID');
const randomId = getLocalStorage || generateRandomId();

// localStorage.setItem('randomID', randomId);

const socket = io(`${socket_url}?user_temp=${randomId}`, {
  transports: ['websocket', 'polling', 'flashsocket'],
});

export default socket;
