import { useAlert } from 'react-alert';
import { useForm } from "react-hook-form";
import MiddleService from '../API/MiddleService';
import React, { useRef, useState, useEffect } from "react";
import { dateFormate, getUserId } from '../Service/service';
import { API_URL } from 'src/API/CommonAPI';

const Profile = () => {
    const alert = useAlert();
    const fileInputRef = useRef(null);

    const [isLoading, setLoading] = useState(false);
    const [binaryData, setBinaryData] = useState('');
    const [mediaURL, setMediaURL] = useState('');
    const [binaryDataImage, setBinaryDataImage] = useState('');
    const [smtoForm, setSMTPForm] = useState({ email: '', password: '', email_error: '', password_error: '' });

    const {
        register,
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        fetchResellerProfile();
        getSMTPDetails();
        setSMTPForm({ email: '', password: '', email_error: '', password_error: '' });
    }, [])

    const fetchResellerProfile = async () => {
        setLoading(true);
        try {
            const payload = { "id": await getUserId() }
            const response = await MiddleService.postData('reseller/getResellerById', payload);

            if (response.status_code === 200) {
                const { full_name, mobile_no, email, logo, id, first_name, last_name, company_name } = response?.data;
                setValue('full_name', full_name);
                setValue('first_name', first_name);
                setValue('last_name', last_name);
                setValue('mobile', mobile_no);
                setValue('email', email);
                setValue('company_name', company_name);
                setValue('id', id);
                setMediaURL(`${API_URL}/uploads/${logo}`)
            } else {
                alert.error('Something Went Wrong !!');
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        setLoading(false);
    };

    const onSubmit = async (data) => {
        const { first_name, company_name, last_name, mobile, email } = data;
        setLoading(true);

        try {
            const formData = new FormData();
            formData.append("first_name", first_name);
            formData.append("last_name", last_name);
            formData.append("mobile_no", mobile);
            formData.append("email", email);
            formData.append("country_code", '+91');
            formData.append("company_name", company_name);
            formData.append("logo", binaryData);


            const response = await MiddleService.postData('reseller/updateResellerProfile', formData);

            if (response.status_code === 200) {
                reset();
                fetchResellerProfile();
                alert.success(response?.message);
            } else {
                alert.error(response?.message);
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        setLoading(false);
    };

    const uploadImage = (e) => {
        let file = e.target.files;

        if (file?.length > 0) {
            setBinaryData(file[0])
            setBinaryDataImage(URL.createObjectURL(file[0]));
        } else {
            setBinaryData("")
        }

    }

    const getSMTPDetails = async () => {
        setLoading(true);
        try {
            const response = await MiddleService.getData('reseller/getAccountData');

            if (response.status_code === 200) {
                console.log("response?.data: - ", response?.data);
                const { email_id, email_password } = response?.data;
                setSMTPForm({ email: email_id, password: email_password });
            } else {
                alert.error('Something Went Wrong !!');
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        setLoading(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setSMTPForm((prev) => ({
            ...prev,
            [name]: value
        }))

    }

    const onSMTPSubmit = async () => {
        const { email, password } = smtoForm;
        setLoading(true);

        if (!email || !password) {

            if (!email && !password) {
                setSMTPForm((prev) => ({
                    ...prev,
                    'email_error': 'Email is Requried',
                    'password_error': 'Password is Requried',
                }))
            } else if (!email) {
                setSMTPForm((prev) => ({
                    ...prev,
                    'email_error': 'Email is Requried'
                }))
            } else {
                setSMTPForm((prev) => ({
                    ...prev,
                    'password_error': 'Password is Requried'
                }))
            }

        } else {
            try {
                const payload = {
                    email_id: email,
                    password: password,
                }
                const response = await MiddleService.postData('reseller/addMailConfiguration', payload);

                if (response.status_code === 200) {
                    setSMTPForm({ email: '', password: '', email_error: '', password_error: '' });
                    getSMTPDetails();
                    alert.success(response?.message);
                } else {
                    alert.error(response?.message);
                }
            } catch (error) {
                console.error(error);
                alert.error('Something Went Wrong !!');
            }
        }

        setLoading(false);
    };

    console.log("smtoForm: - ", smtoForm);

    return (
        <>
            <div class="content-wrapper">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div className="d-flex justify-content-between">
                                    <h4 className="whatsapp-title">Profile</h4>
                                </div>
                                <div>
                                    <form class="pt-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div className='profile-divide'>Personal Info</div>
                                        <div className='justify-content-center'>
                                            <div class="form-group"  style={{position: 'relative'}}>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    onChange={uploadImage}
                                                    ref={fileInputRef}
                                                    style={{ display: 'none' }}
                                                    id="fileInput"
                                                />
                                                <label htmlFor="fileInput">
                                                    <img className='profile-img' src={binaryDataImage || mediaURL || '/images/faces/profileLogo.jpeg'} alt="Click to upload" />
                                                </label>
                                                <label className='cursor-pointer edit-icon-profile' htmlFor="fileInput"><i htmlFor="fileInput" className='fa fa-edit' ></i></label>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label>First Name</label>
                                                    <input
                                                        {...register("first_name", {
                                                            required: 'First Name is requried',
                                                        })}
                                                        class="form-control form-control-lg" placeholder="Enter First Name" />
                                                    {errors?.first_name && <p className="errorMessage">{errors?.first_name?.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label>Last Name</label>
                                                    <input
                                                        {...register("last_name", {
                                                            required: 'Last Name is requried',
                                                        })}
                                                        class="form-control form-control-lg" placeholder="Enter Last Name" />
                                                    {errors?.last_name && <p className="errorMessage">{errors?.last_name?.message}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label>Mobile Number</label>
                                                    <input
                                                        {...register("mobile", {
                                                            required: 'Mobile is requried',
                                                            pattern: {
                                                                value: /^[0-9]{10}$/,
                                                                message: 'Invalid contact number',
                                                            },
                                                        })}
                                                        class="form-control form-control-lg" placeholder="Enter Mobile Number" />
                                                    {errors?.mobile && <p className="errorMessage">{errors?.mobile?.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label>Email</label>
                                                    <input type='email'
                                                        {...register("email", {
                                                            required: 'Email is requried',
                                                        })}
                                                        disabled
                                                        class="form-control form-control-lg" placeholder="Enter Email" />
                                                    {errors?.email && <p className="errorMessage">{errors?.email?.message}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label>Company Name</label>
                                                    <input
                                                        {...register("company_name", {
                                                            required: 'Company Name is requried',
                                                        })}
                                                        class="form-control form-control-lg" placeholder="Enter Company Name" />
                                                    {errors?.company_name && <p className="errorMessage">{errors?.company_name?.message}</p>}
                                                </div>
                                            </div>
                                            {/* <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label>Upload Logo</label>
                                                    <input type='file' className='form-control' onChange={uploadImage} />
                                                </div>
                                                <div>
                                                    {(binaryDataImage || mediaURL) && (
                                                        <div>
                                                            <a className='text-decoration-none color-blue' href={binaryDataImage ? (API_URL + binaryDataImage) : (mediaURL)} target='_blank'>View Uploaded Logo</a>
                                                        </div>
                                                    )}
                                                </div>
                                            </div> */}
                                        </div>
                                        <div class="mt-3 d-flex justify-content-between">
                                            <button class="btn btn-primary btn-rounded br-8 btn-fw" disabled={isLoading}>
                                                {
                                                    isLoading ?
                                                        <>
                                                            <span class="spinner-border spinner-border-sm"></span> Loading...
                                                        </>
                                                        : 'Save'
                                                }
                                            </button>
                                        </div>
                                    </form>

                                    <div className='profile-divide' style={{ marginTop: 25 }} >Email SMTP</div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div class="form-group">
                                                <label>Email Id</label>
                                                <input type="email" name='email' value={smtoForm?.email} onChange={handleChange} class="form-control form-control-lg" placeholder="Enter First Name" />
                                                {smtoForm?.email_error && <p className="errorMessage">{smtoForm?.email_error}</p>}
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div class="form-group">
                                                <label>Password</label>
                                                <input type="password" name='password' onChange={handleChange} class="form-control form-control-lg" placeholder="Enter Password" />
                                                {smtoForm?.password_error && <p className="errorMessage">{smtoForm?.password_error}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-3 d-flex justify-content-between">
                                        <button class="btn btn-primary btn-rounded br-8 btn-fw" onClick={onSMTPSubmit} disabled={isLoading}>
                                            {
                                                isLoading ?
                                                    <>
                                                        <span class="spinner-border spinner-border-sm"></span> Loading...
                                                    </>
                                                    : 'Save'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile;