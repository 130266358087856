export const sidebarMenu = [
    {
        url: "/app/dashboard",
        icon: "icon-grid menu-icon",
        title: "Dashboard",
        activeShow: ["/app", "/app/dashboard"],
        access: ['reseller', 'admin']
    },
    // {
    //     url: "/app/reseller",
    //     icon: "icon-cog  menu-icon",
    //     title: "Reseller List",
    //     activeShow: ["/app/reseller"],
    //     access: ['admin']
    // },
    {
        url: "/app/user",
        icon: "icon-cog  menu-icon",
        title: "User List",
        activeShow: ["/app/user"],
        access: ['reseller', 'admin']
    },
    {
        url: "/app/recharge",
        icon: "icon-cog  menu-icon",
        title: "Recharge History",
        activeShow: ["/app/recharge"],
        access: ['reseller', 'admin']
    },
    // {
    //     url: "/app/services",
    //     icon: "icon-cog  menu-icon",
    //     title: "Services",
    //     activeShow: ["/app/services"],
    //     access: ['reseller', 'admin']
    // },
];