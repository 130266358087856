import axios from 'axios';

class MiddleService {

    postData = async (path, data) => {
        try {
            let response = await axios.post(path, data);
            if (response.status == 200) {
                return response.data;
            } else {
                return false;
            }
        }
        catch (e) {
            if (e?.response?.status === 400) {
                return e?.response?.data
            }
            console.error(e);
        }
    }

    getData = async (path) => {
        try {
            let response = await axios.get(path);
            if (response.status == 200) {
                return response.data;
            } else {
                return false;
            }
        }
        catch (e) {
            if (e?.response?.status === 400) {
                return e?.response?.data
            }
            console.error(e);
        }
    }

}

const instance = new MiddleService();

export default instance;
