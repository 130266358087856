// import React from "react";
// import { Link } from "react-router-dom";

// const LendingPage = () => {
//     return (
//         <>
//             LendingPage
//             <Link to='/fruxinfo' >Login as Admin</Link>
//             <Link to='/partner'>Login as Reseller</Link>
//         </>
//     )
// }

// export default LendingPage;


import React, { useState } from "react";
import { Link } from "react-router-dom";

const Login = () => {

    return (
        <>
            <div class="container-scroller">
                <div class="container-fluid page-body-wrapper full-page-wrapper">
                    <div class="content-wrapper d-flex align-items-center auth px-0">
                        <div class="row w-100 mx-0">
                            <div class="col-lg-4 mx-auto">
                                <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <div class="brand-logo">
                                        <img src="https://www.fruxinfo.com/assets/img/fruxinfo-logo.png" alt="logo" />
                                    </div>
                                    <h4>Hello! let's get started</h4>
                                    <Link class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" style={{marginRight: 15}} to='/fruxinfo' >Login as Admin</Link>
                                    <Link class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" to='/partner' >Login as Partner</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;