import Auth from '../Route/Auth';
import Login from '../Pages/Login';
import Profile from '../Pages/Profile';
import Register from '../Pages/Register';
import LendingPage from '../Pages/LendingPage';
import RegisterOTP from '../Pages/RegisterOTP';
import ClientPublicRoute from './ClientPublicRoute';
import ForgotPassword from '../Pages/ForgotPassword';
import { Route, Routes, Navigate } from 'react-router-dom';
import ForgotPasswordOTP from '../Pages/ForgotPasswordOTP';
import ForgotPasswordMobile from '../Pages/ForgotPasswordMobile';

const BeforeLoginRoute = () => {
    return (
        <>
            <Routes>
                <Route element={<ClientPublicRoute auth={Auth.isAuthenticated()} />} >
                    <Route path="/" element={<Login />} />
                    {/* <Route path="/" element={<LendingPage />} /> */}
                    <Route path="/partner" element={<Login />} />
                    <Route path="/fruxinfo" element={<Login />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/registerOTP" element={<RegisterOTP />} />
                    <Route path="/forgotPassword" element={<ForgotPassword />} />
                    <Route path="/forgotPasswordMobile" element={<ForgotPasswordMobile />} />
                    <Route path="/forgotPasswordOTP" element={<ForgotPasswordOTP />} />
                </Route>
                <Route path="*" element={<Navigate replace={true} to="/" />} />
            </Routes>
        </>
    );
};

export default BeforeLoginRoute;
