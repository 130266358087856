import React, { useRef, useState } from "react";
import axios from 'axios';
import { useForm } from "react-hook-form";
import MiddleService from '../API/MiddleService';
import { Link, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';

const Register = () => {

    const alert = useAlert();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    const password = useRef({});
    password.current = watch('password', '');


    const onSubmit = async (data) => {
        setLoading(true);
        const { address, password, contact, email, firstname, lastname } = data;

        const payload = {
            "first_name": firstname,
            "last_name": lastname,
            "email": email,
            "password": password,
            "mobile_no": contact,
            "country_code": 91,
            "addresse": address
        }

        try {
            const response = await MiddleService.postData('/user/signup', payload);
            const { message } = response || {};
            if (response?.status_code === 200) {
                // alert.success('Register Success');
                navigate('/registerOTP', { state: { mobile_no: contact, user_id: response?.id } });
            } else if (response?.status_code === 400) {
                let errorMessage = '';
                if (response?.error) {
                    const errorArray = Object.values(response?.error);
                    errorMessage = errorArray.reduce((str, current) => {
                        if (current) {
                            str = str + `<div>${current}</div>`;
                        }
                        return str;
                    }, ``)
                    alert.error(<div dangerouslySetInnerHTML={{ __html: errorMessage }} />);
                } else {
                    alert.error(message);
                }
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        setLoading(false);

    };

    return (
        <>
            <div class="container-scroller">
                <div class="container-fluid page-body-wrapper full-page-wrapper">
                    <div class="content-wrapper d-flex align-items-center auth px-0">
                        <div class="row w-100 mx-0">
                            <div class="col-lg-4 mx-auto">
                                <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <div class="brand-logo">
                                        <img src="https://www.fruxinfo.com/assets/img/fruxinfo-logo.png" alt="logo" />
                                    </div>
                                    <h4>Hello! let's get started</h4>
                                    <h6 class="font-weight-light">Sign Up to continue.</h6>
                                    <form class="pt-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div class="form-group">
                                            <input
                                                {...register("firstname", {
                                                    required: 'Firstname is required',
                                                })}
                                                class="form-control form-control-lg" placeholder="First Name" />
                                            {errors?.firstname && <p className="errorMessage">{errors?.firstname?.message}</p>}
                                        </div>
                                        <div class="form-group">
                                            <input
                                                {...register("lastname", {
                                                    required: 'Lastname is required',
                                                })}
                                                class="form-control form-control-lg" placeholder="Last Name" />
                                            {errors?.lastname && <p className="errorMessage">{errors?.lastname?.message}</p>}
                                        </div>
                                        <div class="form-group">
                                            <input
                                                {...register("email", {
                                                    required: 'Email is required',
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: 'Invalid email address',
                                                    },
                                                })}
                                                class="form-control form-control-lg" placeholder="Email" />
                                            {errors?.email && <p className="errorMessage">{errors?.email?.message}</p>}
                                        </div>
                                        <div class="form-group">
                                            <input
                                                {...register("contact", {
                                                    required: 'Contact No is requried',
                                                    pattern: {
                                                        value: /^[0-9]{10}$/,
                                                        message: 'Invalid phone number',
                                                    },
                                                })}
                                                class="form-control form-control-lg" placeholder="Contact No" />
                                            {errors?.contact && <p className="errorMessage">{errors?.contact?.message}</p>}
                                        </div>
                                        <div class="form-group">
                                            <textarea
                                                {...register("address", {
                                                    required: 'Address is required',
                                                })}
                                                class="form-control form-control-lg" placeholder="Address" />
                                            {errors?.address && <p className="errorMessage">{errors?.address?.message}</p>}
                                        </div>
                                        <div class="form-group">
                                            <input
                                                {...register("password", {
                                                    required: 'Password is required',
                                                    minLength: {
                                                        value: 8,
                                                        message: 'Password should have at least 8 characters',
                                                    },
                                                    pattern: {
                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                                                        message:
                                                            'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
                                                    },
                                                })}
                                                type="password" class="form-control form-control-lg" placeholder="Password" />
                                            {errors?.password && <p className="errorMessage">{errors?.password?.message}</p>}
                                        </div>
                                        <div class="form-group">
                                            <input
                                                {...register("confirmPassword", {
                                                    required: 'Confirm Password is required',
                                                    validate: (value) => value === password.current || 'The passwords do not match',
                                                })}
                                                type="password" class="form-control form-control-lg" placeholder="Confirm Password" />
                                            {errors?.confirmPassword && <p className="errorMessage">{errors?.confirmPassword?.message}</p>}
                                        </div>
                                        <div class="mt-3 section-login">
                                            <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" disabled={isLoading}>
                                                {
                                                    isLoading ?
                                                        <>
                                                            <span class="spinner-border spinner-border-sm"></span> Loading...
                                                        </>
                                                        : 'SIGN UP'
                                                }
                                            </button>
                                            <div>
                                                <Link to="/login" className="createAccount" >Sign In</Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register;