import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTable, usePagination } from 'react-table';
import MiddleService from '../API/MiddleService';
import Loader from "../Components/Loader";

const UserList = () => {

    const [pageSize, setPageSize] = useState(10);
    const [userData, setUserData] = useState([]);
    const [isLoading, setLoading] = useState(false);


    let data = [];

    useEffect(() => {
        callAPI();
    }, [])

    const callAPI = async () => {
        setLoading(true);
        const payload = {
            "search": "",
            "startToken": 1,
            "endToken": 10
        }
        try {
            const response = await MiddleService.postData('/user/getUserList', payload);
            if (response) {
                setUserData(convertFormat(response?.data || []));

            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        setLoading(false);
    }

    const convertFormat = (userList) => {
        const result = userList?.map((item) => {
            const { first_name, last_name, mobile_no, email } = item;
            return {
                name: `${first_name || ''} ${last_name || ''}`,
                mobile: mobile_no,
                email: email
            }
        })
        return result;
    }

    const columns = React.useMemo(
        () => [
            { Header: 'Full Name', accessor: 'name' },
            { Header: 'Mobile No', accessor: 'mobile' },
            { Header: 'Email', accessor: 'email' },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        setPageSize: setTablePageSize
    } = useTable(
        { columns, data: userData || [], initialState: { pageIndex: 0, pageSize } },
        usePagination
    );

    const hanldePageSizeChange = (e) => {
        const { value } = e.target;
        setPageSize(value);
        setTablePageSize(value)
    }

    return (
        <>
            {/* <Link to="/app/user/add"> Add User</Link> */}
            <div class="content-wrapper">
                <div class="card">
                    <div class="card-body">
                        {/* <input type="search" class="form-control" placeholder="Search" aria-controls="order-listing"></input> */}
                        <h4 class="card-title">User List</h4>
                        {
                            isLoading ?
                                <Loader />
                                :
                                <div class="table-responsive">
                                    <table {...getTableProps()} class="table table-hover">
                                        <thead>
                                            {headerGroups.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => (
                                                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()}>
                                            {
                                                page.map((row) => {
                                                    prepareRow(row);
                                                    return (
                                                        <tr {...row.getRowProps()}>
                                                            {row.cells.map((cell) => (
                                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                            ))}
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                        }
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info" id="order-listing_info" role="status" aria-live="polite">Showing Page {pageIndex + 1} of {pageOptions.length}</div>
                    </div>
                    <div class="col-sm-12 col-md-7">
                        <div class="dataTables_paginate paging_simple_numbers justify-content-right" id="order-listing_paginate">
                            {/* <div className="">
                                <select onChange={hanldePageSizeChange} value={pageSize}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </select>
                            </div> */}
                            <ul class="pagination">
                                {/* <li class="paginate_button page-item previous" id="order-listing_previous">
                                    <button class="page-link color-black" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                        {'<<'}
                                    </button>
                                </li> */}
                                <li class="paginate_button page-item next" id="order-listing_next">
                                    <button class="page-link color-black" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                        {'Previous'}
                                    </button>
                                </li>
                                <li class="paginate_button page-item active">
                                    <span class="page-link">{pageIndex + 1}</span>
                                </li>
                                <li class="paginate_button page-item next" id="order-listing_next">
                                    <button class="page-link color-black" onClick={() => nextPage()} disabled={!canNextPage}>
                                        {'Next'}
                                    </button>
                                </li>
                                {/* <li class="paginate_button page-item next" id="order-listing_next">
                                    <button class="page-link color-black" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                        {'>>'}
                                    </button>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    {/* <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table> */}

                    {/* <div>
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {'<<'}
                        </button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </button>
                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </button>
                        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            {'>>'}
                        </button>
                        <span>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </span>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default UserList;