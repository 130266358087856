// export const API_URL = 'https://2383-103-177-173-40.in.ngrok.io';
export const API_URL = 'https://api.fruxinfoapi.in/';

// export const API_URL = 'https://whatsapp.fruxinfoapi.in/';
// export const API_URL = 'http://localhost:3000/';

// export const socket_url = 'https://2383-103-177-173-40.in.ngrok.io'; 
export const socket_url = 'http://128.199.26.206:7000'; 

// export const socket_url = 'http://localhost:3000'; 
// export const socket_url = 'https://whatsapp.fruxinfoapi.in'; 

// export const userType = "customer".toLowerCase();