import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import MiddleService from '../API/MiddleService';

const Whatsapp = () => {

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [searchValue, setSearchValue] = useState('');
    const [userList, setUserList] = useState([
        {
            name: 'Isabella Becker',
            image: 'face1.jpg',
            lastMessage: 'Sales dashboard have been created'
        },
        {
            name: 'Adam Warren',
            image: 'face2.jpg',
            lastMessage: 'You have done a great job #TW111'
        },
        {
            name: 'Leonard Thornton',
            image: 'face3.jpg',
            lastMessage: 'Sales dashboard have been created'
        },
        {
            name: 'George Morrison',
            image: 'face4.jpg',
            lastMessage: 'Sales dashboard have been created'
        },
        {
            name: 'Ryan Cortez',
            image: 'face5.jpg',
            lastMessage: 'Herbs are fun and easy to grow.'
        },
        {
            name: 'Isabella Becker',
            image: 'face1.jpg',
            lastMessage: 'Sales dashboard have been created'
        },
        {
            name: 'Adam Warren',
            image: 'face2.jpg',
            lastMessage: 'You have done a great job #TW111'
        },
        {
            name: 'Leonard Thornton',
            image: 'face3.jpg',
            lastMessage: 'Sales dashboard have been created'
        },
        {
            name: 'George Morrison',
            image: 'face4.jpg',
            lastMessage: 'Sales dashboard have been created'
        },
        {
            name: 'Ryan Cortez',
            image: 'face5.jpg',
            lastMessage: 'Herbs are fun and easy to grow.'
        },
    ]);
    const [searchResult, setSearchResult] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [mobileNumber, setMobileNumber] = useState('7990707337');

    useEffect(() => {
        const result = userList?.filter(({ name }) => name?.toLowerCase().includes(searchValue.toLowerCase()));
        setSearchResult(result);
    }, [searchValue])

    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
    };

    const handleSave = () => {
        const content = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
        // Perform your save logic with the content
    };

    const handleClick = (item) => {
        window.scrollTo(1000, 0);
        var contentElement = document?.getElementById('content');
        contentElement?.scrollBy(250, 0);
        setSelectedUser(item);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchValue(value);
    }

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const fetchData = async () => {
        // Make an API request to fetch data based on the current page
        // Prepend the new data to the existing data array
        // Update the page number and hasMore flag based on the API response
        const tempData = [
            { message: 'Hello how are you?', type: 'left' },
            { message: "I'm good, thanks for asking!", type: 'right' },
            { message: 'Thats great to hear!', type: 'left' },
            { message: 'How about you? How are you doing?', type: 'right' },
            { message: "I'm doing well too. Thanks!", type: 'left' },
            { message: 'Thats great!', type: 'right' },
            { message: 'By the way, did you watch the latest episode of that TV show?', type: 'left' },
            { message: 'No, I missed it. Was it any good?', type: 'right' },
            { message: 'It was amazing! You should definitely check it out.', type: 'left' },
            { message: 'I will definitely catch up on it. Thanks for the recommendation!', type: 'right' }
        ].reverse();

        setData(tempData)
    };


    const fetchDataDemo = async () => {
        // Make an API request to fetch data based on the current page
        // Prepend the new data to the existing data array
        // Update the page number and hasMore flag based on the API response

        const tempData = [
            { message: 'Hello how are you?', type: 'left' },
            { message: "I'm good, thanks for asking!", type: 'right' },
            { message: 'Thats great to hear!', type: 'left' },
            { message: 'How about you? How are you doing?', type: 'right' },
            { message: "I'm doing well too. Thanks!", type: 'left' },
            { message: 'Thats great!', type: 'right' },
            { message: 'By the way, did you watch the latest episode of that TV show?', type: 'left' },
            { message: 'No, I missed it. Was it any good?', type: 'right' },
            { message: 'It was amazing! You should definitely check it out.', type: 'left' },
            { message: 'I will definitely catch up on it. Thanks for the recommendation!', type: 'right' }
        ].reverse();

        setData([...data, ...tempData]);
        setHasMore(false);
    };

    useEffect(() => {
        fetchData(); // Fetch initial data when the component mounts
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Access the content from the editorState
        const content = editorState && editorState.getCurrentContent();
        const value = content && content.getPlainText();

        try {
            const response = await MiddleService.postData('whatsapp/sendmessage', { "mobile_no": mobileNumber, "message": value });
            if (response) {
                setEditorState(() => EditorState.createEmpty());
                // setMobileNumber('');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <div class="content-wrapper">
                <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                        <div class="card whatsapp-card">
                            <div class="card-body p-0 d-flex" id="content">
                                {/* <div className="row"> */}
                                <div className="user-list p-0">
                                    <div className="user-list-header">
                                        <i className="fa fa-search"></i>
                                        <input type="text" value={searchValue} class="search-input form-control form-control-sm" placeholder="Search" aria-label="Username" onChange={handleChange} />
                                    </div>
                                    <div className="user-inbox">
                                        INBOX
                                    </div>
                                    <div className={`p-2 user-list-index ${searchResult?.length ? '' : 'contactNotFound'}`}>
                                        {
                                            searchResult?.length ?
                                                <ul class="user-icon-data-list icon-data-list">
                                                    <li >
                                                        <div class="d-flex align-items-center">
                                                            <input type="text" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                                                        </div>
                                                    </li>
                                                    {
                                                        searchResult?.map((item, index) => {
                                                            return (
                                                                <li key={`${item.name}_${index}`} onClick={() => handleClick(item)}>
                                                                    <div class="d-flex align-items-center">
                                                                        <img src={`../../images/faces/${item.image}`} alt="user" />
                                                                        <div>
                                                                            <p class="text-info mb-1">{item.name}</p>
                                                                            <p class="mb-0 text-truncat-message">{item.lastMessage}</p>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                :
                                                <div className="contactNotFound">No contact found</div>
                                        }
                                    </div>
                                </div>
                                <div className="chat-messages p-0">
                                    {
                                        selectedUser ?
                                            <>
                                                <div className="chat-message-header">
                                                    <div className="profile-image">
                                                        <img src={`../../images/faces/${selectedUser.image}`} alt="user" />
                                                    </div>
                                                    <div className="profile-name">
                                                        {selectedUser.name}
                                                    </div>
                                                </div>
                                                <div className="chat-part p-4">
                                                    <div id="scrollableDiv">
                                                        <InfiniteScroll
                                                            dataLength={data.length}
                                                            // next={() => setPage(page + 1)}
                                                            next={fetchDataDemo}
                                                            style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                                                            inverse={true} //
                                                            hasMore={hasMore}
                                                            loader={<h4>Loading...</h4>}
                                                            scrollableTarget="scrollableDiv"
                                                        >
                                                            {data.map((item, index) => {
                                                                const { message, type } = item;
                                                                return (
                                                                    <div key={index} className={`messageItem ${type == 'left' ? 'messageLeft' : 'messageRight'}`}>
                                                                        <div className={`message ${type == 'left' ? 'messageLeftColor' : 'messageRightColor'}`}>{message}</div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </InfiniteScroll>
                                                    </div>
                                                </div>
                                                <div className="messagebox">
                                                    <form onSubmit={handleSubmit} className="d-flex">
                                                        {/* <input type="text" class="form-control form-control-sm" placeholder="Search" aria-label="Username" /> */}
                                                        <div>
                                                            <Editor
                                                                editorState={editorState}
                                                                onEditorStateChange={handleEditorChange}
                                                            />
                                                        </div>
                                                        <div>
                                                            <button type="submit" title="Send Messages" className="sendMessageButton"><i className="fa fa-paper-plane"></i></button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="pt-5">
                                                    <div className="justify-content-center">
                                                        <img className="whatsappImageChat" width={300} height={300} src="https://marketplace.canva.com/EAFEits4-uw/1/0/800w/canva-boy-cartoon-gamer-animated-twitch-profile-photo-r0bPCSjUqg0.jpg"></img>
                                                    </div>
                                                    <h3 className="justify-content-center pt-4 color-blue">Connect Whatsapp in less than 10 seconds</h3>
                                                    <p className="justify-content-center pt-2 color-blue">
                                                        Sync your WhatsApp to take advantage of all Kommo’s tools for messenger-based sales
                                                    </p>
                                                    <div className="justify-content-center pt-2 ">
                                                        <button type="button" class="btn btn-primary btn-rounded btn-fw">Connect Whatsapp</button>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Whatsapp;