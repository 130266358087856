import React from "react";

const Services = () => {

    const service = [
        {
            img: 'whatsapp.jpeg',
            name: 'Whatsapp',
            desc: 'Launch soon'
        },
        {
            img: 'instagram.jpeg',
            name: 'Instagram',
            desc: 'Comming Soon'
        },
        {
            img: 'messanger.jpeg',
            name: 'Facebook Messanger',
            desc: 'Comming Soon'
        },
        {
            img: 'text.jpeg',
            name: 'Text',
            desc: 'Comming Soon'
        },
        {
            img: 'teligram.jpeg',
            name: 'Teligram',
            desc: 'Comming Soon'
        },
    ]

    return (
        <>
            <div class="content-wrapper">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row portfolio-grid">
                                            {
                                                service?.length && service?.map((item, index) => {
                                                    const { img, name, desc } = item;
                                                    return (
                                                        <div key={`i${name}_${index}`} class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                            <figure class="effect-text-in">
                                                                <img className="object-fit-cover" src={`../../../../images/samples/300x300/${img}`} width={100} height={200} alt="image" />
                                                                <figcaption>
                                                                    <h4>{name}</h4>
                                                                    <p>{desc}</p>
                                                                </figcaption>
                                                            </figure>
                                                        </div>
                                                    )
                                                })
                                            }

                                            {/* <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                <figure class="effect-text-in">
                                                    <img src="../../../../images/samples/300x300/2.jpg" alt="image" />
                                                    <figcaption>
                                                        <h4>Lifestyle</h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                <figure class="effect-text-in">
                                                    <img src="../../../../images/samples/300x300/3.jpg" alt="image" />
                                                    <figcaption>
                                                        <h4>Tech Geeks</h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                <figure class="effect-text-in">
                                                    <img src="../../../../images/samples/300x300/4.jpg" alt="image" />
                                                    <figcaption>
                                                        <h4>Explore World</h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                <figure class="effect-text-in">
                                                    <img src="../../../../images/samples/300x300/5.jpg" alt="image" />
                                                    <figcaption>
                                                        <h4>Travel</h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                <figure class="effect-text-in">
                                                    <img src="../../../../images/samples/300x300/6.jpg" alt="image" />
                                                    <figcaption>
                                                        <h4>Colors</h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                <figure class="effect-text-in">
                                                    <img src="../../../../images/samples/300x300/7.jpg" alt="image" />
                                                    <figcaption>
                                                        <h4>Through Nature</h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                <figure class="effect-text-in">
                                                    <img src="../../../../images/samples/300x300/8.jpg" alt="image" />
                                                    <figcaption>
                                                        <h4>World</h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                <figure class="effect-text-in">
                                                    <img src="../../../../images/samples/300x300/9.jpg" alt="image" />
                                                    <figcaption>
                                                        <h4>Cultures</h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                <figure class="effect-text-in">
                                                    <img src="../../../../images/samples/300x300/10.jpg" alt="image" />
                                                    <figcaption>
                                                        <h4>Habits</h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                <figure class="effect-text-in">
                                                    <img src="../../../../images/samples/300x300/11.jpg" alt="image" />
                                                    <figcaption>
                                                        <h4>Being yourself</h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                <figure class="effect-text-in">
                                                    <img src="../../../../images/samples/300x300/12.jpg" alt="image" />
                                                    <figcaption>
                                                        <h4>Nature</h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                <figure class="effect-text-in">
                                                    <img src="../../../../images/samples/300x300/13.jpg" alt="image" />
                                                    <figcaption>
                                                        <h4>Routines</h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                <figure class="effect-text-in">
                                                    <img src="../../../../images/samples/300x300/14.jpg" alt="image" />
                                                    <figcaption>
                                                        <h4>Life</h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                                <figure class="effect-text-in">
                                                    <img src="../../../../images/samples/300x300/15.jpg" alt="image" />
                                                    <figcaption>
                                                        <h4>Vision</h4>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                    </figcaption>
                                                </figure>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services;