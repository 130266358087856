import dayjs from 'dayjs';

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const generateRandomId = () => {
  let token = fetchAuthToken()
  const decodedJwt = parseJwt(token?.replace("Bearer ", ""));
  return localStorage.setItem("randomID", decodedJwt?.user_code)
};

export const fetchUserName = () => {
  return localStorage.getItem("userName")
}

// export const generateRandomId = () => {
//   const randomNumber = Math.floor(1000000 + Math.random() * 9000000);
//   return randomNumber.toString().substring(0, 7);
// };

export const getUserId = () => {
  let token = fetchAuthToken()
  const decodedJwt = parseJwt(token?.replace("Bearer ", ""));
  return 1 //decodedJwt?.user_id
}

export const fetchAuthToken = () => {
  return localStorage.getItem("whatsappAuth")
}

export const fetchUserType = () => {
  return localStorage.getItem("userType");
}

export const isResellerUser = () => {
  return true;
  // return !!(localStorage.getItem("userType") === 'reseller');
}

export const isAdminUser = () => {
  return !!(localStorage.getItem("userType") === 'admin');
}

export const dateFormate = (customDate) => {
  if (!customDate) {
    return ""
  }
  return dayjs(customDate).format("DD-MM-YYYY")
}

export const dateTimeFormat = (customDate) => {
  if (!customDate) {
    return ""
  }
  return dayjs(customDate).format("DD-MM-YYYY hh:mm a")
}

export const isAllowDelete = ({ createdAt }) => {
  if (!createdAt) {
    return false
  }

  const creationTimestamp = dayjs(createdAt);
  const currentTimestamp = dayjs();
  const timeDifference = currentTimestamp.diff(creationTimestamp, 'minute');
  const editingTimeLimit = 5;
  if (timeDifference <= editingTimeLimit) {
    return true;
  } 
  return false;
}