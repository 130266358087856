export const userRoute = [
    {
        path: '/',
        exact: true,
        component: 'Dashboard',
        access: ['reseller', 'admin']
    },
    {
        path: '/dashboard',
        exact: true,
        component: 'Dashboard',
        access: ['reseller', 'admin']
    },
    // {
    //     path: '/user',
    //     exact: true,
    //     component: 'UserList',
    //     access: ['admin']
    // },
    {
        path: '/user/add',
        exact: true,
        component: 'AddUser',
        access: ['admin']
    },
    {
        path: '/whatsapp/QR',
        exact: true,
        component: 'QRCodePage',
        access: ['reseller', 'admin']
    },
    {
        path: '/whatsappMessage',
        exact: true,
        component: 'WhatsappMessage',
        access: ['reseller', 'admin']
    },
    {
        path: '/whatsapp',
        exact: true,
        component: 'Whatsapp',
        access: ['reseller', 'admin']
    },
    {
        path: '/services',
        exact: true,
        component: 'Services',
        access: ['reseller', 'admin']
    },
    {
        path: '/reseller',
        exact: true,
        component: 'Reseller',
        access: ['admin']
    },
    {
        path: '/changePassword',
        exact: true,
        component: 'ChangePassword',
        access: ['reseller', 'admin']
    },
    {
        path: '/recharge',
        exact: true,
        component: 'Recharge',
        access: ['reseller', 'admin']
    },
    {
        path: '/user',
        exact: true,
        component: 'User',
        access: ['reseller', 'admin']
    },
    {
        path: '/profile',
        exact: true,
        component: 'Profile',
        access: ['reseller']
    },
];