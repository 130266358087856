import React, { useState } from "react";
import { useAlert } from 'react-alert';
import { useForm } from "react-hook-form";
import MiddleService from '../API/MiddleService';
import { useNavigate, Link } from 'react-router-dom';

const ForgotPasswordMobile = () => {

    const alert = useAlert();
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors }
    } = useForm();

    const onSubmit = async (data) => {
        const { contact } = data;

        setLoading(true);
        try {
            const response = await MiddleService.postData('reseller/sendForgotPasswodOtp', { "mobile_no": contact });

            if (response.status_code === 200) {
                const { user_id } = response;
                navigate('/forgotPasswordOTP', { state: { mobile_no: contact, user_id } });
                reset();
                // alert.success(response?.message);
            } else {
                alert.error(response?.message);
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        setLoading(false);
    };

    return (
        <>
            <div class="container-scroller">
                <div class="container-fluid page-body-wrapper full-page-wrapper">
                    <div class="content-wrapper d-flex align-items-center auth px-0">
                        <div class="row w-100 mx-0">
                            <div class="col-lg-4 mx-auto">
                                <div style={{ marginBottom: 5 }}>
                                    <Link to="/" className="createAccount mt-5" >
                                        <i class="fa fa-chevron-left" aria-hidden="true" style={{ marginRight: 5 }}></i>
                                        Back
                                    </Link>
                                </div>
                                <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <div class="brand-logo text-center">
                                        <img src="/images/Fruxinfo_Logo.png" alt="logo" />
                                    </div>
                                    <h6 class="font-weight-light">Enter Contact Number</h6>
                                    <form class="pt-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div class="form-group">
                                            <div className="w-100">
                                                <input
                                                    {...register("contact", {
                                                        required: 'Contact No is requried',
                                                        pattern: {
                                                            value: /^[0-9]{10}$/,
                                                            message: 'Invalid phone number',
                                                        },
                                                    })}
                                                    class="form-control form-control-lg" placeholder="Contact No" />
                                                {errors?.contact && <p className="errorMessage">{errors?.contact?.message}</p>}
                                            </div>
                                        </div>
                                        <div class="mt-3 section-login">
                                            <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" disabled={isLoading}>
                                                {
                                                    isLoading ?
                                                        <>
                                                            <span class="spinner-border spinner-border-sm"></span> Loading...
                                                        </>
                                                        : 'Next'
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPasswordMobile;